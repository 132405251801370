import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ClinicDependent,
  ClinicDetailedPatient,
  ClinicPatientRegistration,
  GlobalBookingStatistics,
  InsuranceDetailsItem,
  MergeProfilesData,
  Patient,
  PatientAccountBalance,
  PatientBadgeMerge,
  PatientCategories,
  PatientClassification,
  PatientGuardian,
  PatientLog,
  PatientMerge,
  PatientStatistics,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RelatedProfile,
  UserShortInfo,
} from 'app/types';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AddClinicPatientParams,
  AddDependentGuardiansBody,
  AddInsuranceDetailsBody,
  AddPatientClassificationParams,
  CheckClinicGovernmentIssuedIdParams,
  CheckGlobalGovernmentIssuedIdParams,
  CheckGovernmentIssuedIdResponse,
  CheckIfRevertMergeIsPossibleBody,
  ClinicPatientShortInfo,
  ConvertClinicDependentsPatientParams,
  EditClinicDependentsPatientParams,
  EditClinicPatientParams,
  EditInsuranceDetailsBody,
  MergeRequestPatientBodyParams,
  PatientBodyParams,
  PatientItem,
  PatientShortDetailsOption,
  PatientShortInfo,
  SwitchClinicDependentsPatientParams,
  VerificationCodeMergeRequestPatientBodyParams,
  VerifyPatientProfileParams,
} from 'app/services/patients/types';
import { PatientIdentityVerificationLog, PatientLinkHistoryItem } from 'app/types';

export const patientsApi = createApi({
  reducerPath: 'patientsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'Patients',
    'MedicalInstitutionPatients',
    'PatientLog',
    'ClinicPatientLog',
    'PatientCategories',
    'PatientDependents',
    'PatientInfo',
    'PatientInsuranceDetails',
    'PatientRegistrations',
    'PatientGlobalRating',
    'MedicalInstitutionPatientRating',
    'MedicalInstitutionPatientCategories',
    'MedicalInstitutionPatientInsuranceDetails',
    'MedicalInstitutionPatientInfo',
    'MedicalInstitutionPatientGuardians',
    'MedicalInstitutionDependents',
    'MedicalInstitutionRelatedPatient',
    'MergeClinicPatient',
    'PatientAccountBalance',
  ],
  endpoints: (builder) => ({
    // Patients
    getEditDependentInfo: builder.query<QueryResponse<any>, string>({
      // [i]: Data for edit dependent modal
      query: (dependantClinicPatientId) => ({
        url: `dependants/${dependantClinicPatientId}`,
        method: 'GET',
      }),
    }),

    searchPatientByPhoneNumber: builder.query<QueryResponseWithPagination<PatientShortInfo[]>, QueryParams | undefined>(
      {
        query: (params) => ({
          url: 'patients/small-info',
          method: 'GET',
          params,
        }),
      },
    ),

    searchClinicPatientByPhoneNumber: builder.query<
      QueryResponseWithPagination<ClinicPatientShortInfo[]>,
      { params: QueryParams | undefined; clinicId: string }
    >({
      query: ({ params, clinicId }) => ({
        url: `clinics/${clinicId}/clinic-patients/small-info`,
        params,
      }),
      providesTags: ['Patients', 'MedicalInstitutionPatients'],
    }),

    getPatients: builder.query<QueryResponseWithPagination<PatientItem[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'patients',
        params,
      }),
      providesTags: ['Patients'],
    }),

    getPatientDependents: builder.query<
      QueryResponseWithPagination<ClinicDependent[]>,
      { params: QueryParams | undefined; patientId: string }
    >({
      query: ({ params, patientId }) => ({
        url: `patients/${patientId}/dependants`,
        params,
      }),
      providesTags: ['PatientDependents'],
    }),

    getClinicPatientDependents: builder.query<
      QueryResponseWithPagination<ClinicDependent[]>,
      { params: QueryParams | undefined; patientId: string }
    >({
      query: ({ params, patientId }) => ({
        url: `clinic-patients/${patientId}/dependants`,
        params,
      }),
      providesTags: ['MedicalInstitutionDependents'],
    }),

    getPatientRegistrations: builder.query<
      QueryResponseWithPagination<ClinicPatientRegistration[]>,
      { params: QueryParams | undefined; patientId: string }
    >({
      query: ({ params, patientId }) => ({
        url: `patients/${patientId}/registrations`,
        params,
      }),
      providesTags: ['PatientRegistrations'],
    }),

    updatedClinicPatient: builder.mutation<void, { clinicId: string; patientId: string; clinicPatientId: string }>({
      query: ({ clinicId, patientId, clinicPatientId }) => ({
        url: `clinics/${clinicId}/clinic-patients/${patientId}/update-from-clinic-patient/${clinicPatientId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['MedicalInstitutionPatientInfo', 'MedicalInstitutionRelatedPatient'],
    }),

    updatedPatient: builder.mutation<void, { patientId: string; clinicPatientId: string }>({
      query: ({ patientId, clinicPatientId }) => ({
        url: `patients/${patientId}/update-from-clinic-patient/${clinicPatientId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientInfo', 'PatientRegistrations'],
    }),

    getPatientGlobalRating: builder.query<QueryResponse<GlobalBookingStatistics>, string>({
      query: (patientId) => ({
        url: `patients/${patientId}/global-booking-statistic`,
      }),
      providesTags: ['PatientGlobalRating'],
    }),

    getMedicalInstitutionPatientRating: builder.query<
      QueryResponse<PatientStatistics>,
      { clinicId: string; patientId: string }
    >({
      query: ({ clinicId, patientId }) => ({
        url: `clinic-patients/${patientId}/booking-statistic`,
      }),
      providesTags: ['MedicalInstitutionPatientRating'],
    }),

    getClinicPatients: builder.query<
      QueryResponseWithPagination<PatientItem[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/clinic-patients`,
        params,
      }),
      providesTags: ['MedicalInstitutionPatients'],
    }),

    getPatientPageDetails: builder.query<QueryResponse<Patient>, { clinicId?: string; patientId: string }>({
      query: ({ clinicId, patientId }) => {
        const url = clinicId
          ? `clinics/${clinicId}/clinic-patients/${patientId}/page-details`
          : `patients/${patientId}/page-details`;

        return {
          url,
          method: 'GET',
        };
      },
    }),

    // Permissions restriction free endpoint
    getPatientShortDetails: builder.query<
      QueryResponse<PatientShortDetailsOption>,
      {
        clinicId?: string;
        patientId: string;
      }
    >({
      query: ({ clinicId, patientId }) => {
        const url = clinicId
          ? `clinics/${clinicId}/clinic-patients/${patientId}/short-details`
          : `patients/${patientId}/short-details`;

        return {
          method: 'GET',
          url,
        };
      },
    }),

    addPatient: builder.mutation<QueryResponse<Patient>, { body: PatientBodyParams }>({
      query: ({ body }) => ({
        url: `patients`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Patients'],
    }),
    updateStatistics: builder.mutation<void, { clinicPatientId: string }>({
      query: ({ clinicPatientId }) => ({
        url: `clinic-patients/${clinicPatientId}/recalculate-statistics`,
        method: 'POST',
      }),
      invalidatesTags: ['MedicalInstitutionPatientRating'],
    }),
    editPatient: builder.mutation<void, { patientId: string; body: PatientBodyParams }>({
      query: ({ patientId, body }) => ({
        url: `patients/${patientId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Patients', 'PatientRegistrations', 'PatientInfo'],
    }),

    verifyPatientProfile: builder.mutation<void, VerifyPatientProfileParams>({
      query: ({ body, clinicPatientId, clinicId }) => ({
        url: `clinics/${clinicId}/clinic-patients/${clinicPatientId}/update-with-verify`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatients', 'MedicalInstitutionPatientInfo'],
    }),

    addClinicPatient: builder.mutation<QueryResponse<Patient>, AddClinicPatientParams>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/clinic-patients`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatients'],
    }),

    addClinicPatientDependent: builder.mutation<QueryResponse<Patient>, AddClinicPatientParams>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/dependants`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionDependents', 'MedicalInstitutionPatients', 'MedicalInstitutionPatientInfo'],
    }),

    editClinicPatientDependent: builder.mutation<void, EditClinicDependentsPatientParams>({
      query: ({ clinicId, dependentId, body }) => ({
        url: `clinics/${clinicId}/dependants/${dependentId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientDependents', 'MedicalInstitutionPatients', 'MedicalInstitutionDependents'],
    }),

    deleteClinicPatientDependent: builder.mutation<void, { clinicId: string; dependentId: string }>({
      query: ({ clinicId, dependentId }) => ({
        url: `clinic-patients/${clinicId}/dependants/${dependentId}/link`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PatientDependents', 'MedicalInstitutionDependents'],
    }),

    switchClinicPatientDependent: builder.mutation<void, SwitchClinicDependentsPatientParams>({
      query: ({ patientId, dependentId, body }) => ({
        url: `dependants/${dependentId}/patients/${patientId}/switch`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientDependents', 'MedicalInstitutionDependents'],
    }),

    convertClinicPatientDependent: builder.mutation<void, ConvertClinicDependentsPatientParams>({
      query: ({ dependentId, body }) => ({
        url: `dependants/${dependentId}/convert-to-patient`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientDependents', 'MedicalInstitutionDependents'],
    }),

    editClinicPatient: builder.mutation<void, EditClinicPatientParams>({
      query: ({ clinicId, clinicPatientId, body }) => ({
        url: `clinics/${clinicId}/clinic-patients/${clinicPatientId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatients', 'PatientRegistrations', 'MedicalInstitutionPatientInfo'],
    }),

    getRelatedPatientsForAppointment: builder.query<
      QueryResponseWithPagination<ClinicPatientShortInfo[]>,
      { patientId: string; phoneNumber: string }
    >({
      query: ({ patientId, phoneNumber }) => ({
        url: `clinic-patients/${patientId}/related-patients/appointment`,
        params: { phoneNumber },
      }),
      providesTags: ['Patients', 'MedicalInstitutionPatients', 'MedicalInstitutionDependents'],
    }),

    // Logs
    getLinkingHistory: builder.query<
      QueryResponseWithPagination<PatientLinkHistoryItem[]>,
      { params: QueryParams | undefined; clinicPatientId: string }
    >({
      query: ({ clinicPatientId, params }) => ({
        url: `clinic-patients/${clinicPatientId}/link-to-global-patient-history`,
        method: 'GET',
        params,
      }),
    }),

    requireVerification: builder.mutation<void, string>({
      query: (clinicPatientId) => ({
        url: `/clinic-patients/${clinicPatientId}/require-verification`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientLog', 'PatientRegistrations', 'PatientDependents', 'PatientInfo'],
    }),
    cancelRequiredVerification: builder.mutation<void, string>({
      query: (clinicPatientId) => ({
        url: `/clinic-patients/${clinicPatientId}/cancel-require-verification`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientLog', 'PatientRegistrations', 'PatientDependents', 'PatientInfo'],
    }),

    cancelPatientRequiredVerification: builder.mutation<void, string>({
      query: (patientId) => ({
        url: `patients/${patientId}/cancel-require-verification`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientInfo'],
    }),

    verify: builder.mutation<void, string>({
      query: (clinicPatientId) => ({
        url: `/clinic-patients/${clinicPatientId}/verify`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientLog', 'PatientRegistrations', 'PatientDependents'],
    }),
    getPatientLog: builder.query<
      QueryResponseWithPagination<PatientLog[]>,
      {
        patientId: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/action-logs`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),
    getMedicalInstitutionsPatientLog: builder.query<
      QueryResponseWithPagination<PatientLog[]>,
      {
        patientId: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/clinic-patient-action-logs`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),
    getPatientLogExport: builder.query<
      void,
      {
        patientId: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/action-logs/excel`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),
    getMedicalInstitutionsPatientLogExport: builder.query<
      void,
      {
        patientId: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/clinic-patient-action-logs/excel`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),
    getClinicPatientLogExport: builder.query<void, { clinicPatientId: string; params: QueryParams | undefined }>({
      query: ({ clinicPatientId, params }) => ({
        url: `clinic-patients/${clinicPatientId}/action-logs/excel`,
        params,
      }),
      providesTags: ['ClinicPatientLog'],
    }),
    getClinicPatientLog: builder.query<
      QueryResponseWithPagination<PatientLog[]>,
      { clinicPatientId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicPatientId, params }) => ({
        url: `/clinic-patients/${clinicPatientId}/action-logs`,
        params,
      }),
      providesTags: ['ClinicPatientLog'],
    }),

    getPatientIdentityLog: builder.query<
      QueryResponseWithPagination<PatientIdentityVerificationLog[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/verification-logs`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),

    // Merges
    getPatientMergeExport: builder.query<void, { patientId: string; params: QueryParams | undefined }>({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/merge-logs/excel`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),

    getPatientMerge: builder.query<
      QueryResponseWithPagination<PatientMerge[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `/patients/${patientId}/merge-logs`,
        params,
      }),
      providesTags: ['PatientLog'],
    }),

    getClinicPatientMergeExport: builder.query<void, { clinicPatientId: string; params: QueryParams | undefined }>({
      query: ({ clinicPatientId, params }) => ({
        url: `/clinic-patients/${clinicPatientId}/merge-logs/excel`,
        params,
      }),
      providesTags: ['ClinicPatientLog'],
    }),

    getClinicPatientMerge: builder.query<
      QueryResponseWithPagination<PatientMerge[]>,
      { clinicPatientId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicPatientId, params }) => ({
        url: `/clinic-patients/${clinicPatientId}/merge-logs`,
        params,
      }),
      providesTags: ['ClinicPatientLog'],
    }),

    checkIfRevertMergeIsPossible: builder.query<
      QueryResponse<CheckIfRevertMergeIsPossibleBody>,
      { mergedFromClinicPatientId: string }
    >({
      query: ({ mergedFromClinicPatientId }) => ({
        url: `clinic-patients/${mergedFromClinicPatientId}/check-unmerge-is-possible`,
        method: 'GET',
      }),
      providesTags: ['PatientLog'],
    }),
    mergedByInfo: builder.query<QueryResponse<UserShortInfo>, { mergedById: string }>({
      query: ({ mergedById }) => ({
        url: `users/${mergedById}/small-info`,
        method: 'GET',
      }),
      providesTags: ['PatientLog'],
    }),

    unmergePatients: builder.mutation<void, { fromClinicPatientId: string; toClinicPatientId: string }>({
      query: ({ fromClinicPatientId, toClinicPatientId }) => ({
        url: `clinic-patients/${fromClinicPatientId}/unmerge-to/${toClinicPatientId}`,
        method: 'POST',
      }),
      invalidatesTags: [
        'MedicalInstitutionRelatedPatient',
        'MedicalInstitutionDependents',
        'MedicalInstitutionPatientInfo',
      ],
    }),

    checkUnmergeIsPossible: builder.query<QueryResponse<CheckIfRevertMergeIsPossibleBody>, string>({
      query: (mergedFromClinicPatientId) => ({
        url: `clinic-patients/${mergedFromClinicPatientId}/check-unmerge-is-possible`,
        method: 'GET',
      }),
    }),

    getPatientMergeBadgeDetails: builder.query<QueryResponse<PatientBadgeMerge[]>, { entityId: string }>({
      query: ({ entityId }) => ({
        url: `/entities/${entityId}/merge-patients-badges`,
        method: 'GET',
      }),
    }),
    getPatientListMergeBadgeDetails: builder.query<
      QueryResponse<PatientBadgeMerge>,
      { sourceClinicPatientId: string; destinationClinicPatientId: string }
    >({
      query: ({ sourceClinicPatientId, destinationClinicPatientId }) => ({
        url: `/clinic-patient/${sourceClinicPatientId}/merged-into/${destinationClinicPatientId}/merge-patients-badge`,
        method: 'GET',
      }),
    }),

    getClinicPatientClassification: builder.query<
      QueryResponseWithPagination<PatientCategories[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/patient-categories`, params }),
      providesTags: ['MedicalInstitutionPatientCategories'],
    }),
    getPatientClassification: builder.query<QueryResponseWithPagination<PatientCategories[]>, QueryParams | undefined>({
      query: (params) => ({ url: 'patient-categories', params }),
      providesTags: ['PatientCategories'],
    }),
    classificationMassAdding: builder.mutation<void, { body: FormData }>({
      query: ({ body }) => ({
        url: `patient-categories/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientCategories'],
    }),
    clinicPatientClassificationMassAdding: builder.mutation<void, { clinicId: string; body: FormData }>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/patient-categories/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),
    addClinicPatientClassification: builder.mutation<void, { clinicId: string; body: AddPatientClassificationParams }>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/patient-categories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),
    addPatientClassification: builder.mutation<void, AddPatientClassificationParams>({
      query: (body) => ({
        url: `patient-categories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientCategories'],
    }),
    editClinicPatientClassification: builder.mutation<
      void,
      { patientCategoryId: string; body: AddPatientClassificationParams }
    >({
      query: ({ patientCategoryId, body }) => ({
        url: `clinics/patient-categories/${patientCategoryId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),
    editPatientClassification: builder.mutation<
      void,
      { patientCategoryId: string; body: AddPatientClassificationParams }
    >({
      query: ({ patientCategoryId, body }) => ({
        url: `patient-categories/${patientCategoryId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientCategories'],
    }),
    movePatientClassification: builder.mutation<void, { patientCategoryId: string; patientCategoryToMoveId: string }>({
      query: ({ patientCategoryId, patientCategoryToMoveId }) => ({
        url: `patient-categories/${patientCategoryId}/move/${patientCategoryToMoveId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientCategories'],
    }),
    moveClinicPatientClassification: builder.mutation<
      void,
      { patientCategoryId: string; patientCategoryToMoveId: string }
    >({
      query: ({ patientCategoryId, patientCategoryToMoveId }) => ({
        url: `clinics/patient-categories/${patientCategoryId}/move/${patientCategoryToMoveId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),
    deletePatientClassification: builder.mutation<void, { patientCategoryId: string; patientCategoryToMoveId: string }>(
      {
        query: ({ patientCategoryId, patientCategoryToMoveId }) => ({
          url: `patient-categories/${patientCategoryId}`,
          method: 'DELETE',
          params: { patientCategoryToMoveId },
        }),
        invalidatesTags: ['PatientCategories'],
      },
    ),
    deleteClinicPatientClassification: builder.mutation<
      void,
      { patientCategoryId: string; patientCategoryToMoveId: string }
    >({
      query: ({ patientCategoryId, patientCategoryToMoveId }) => ({
        url: `clinics/patient-categories/${patientCategoryId}`,
        method: 'DELETE',
        params: { patientCategoryToMoveId },
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),
    getPatientClassificationInfo: builder.query<
      QueryResponseWithPagination<PatientClassification[]>,
      { params: QueryParams | undefined; patientCategoryId: string }
    >({
      query: ({ params, patientCategoryId }) => ({ url: `patient-categories/${patientCategoryId}/patients`, params }),
      providesTags: ['PatientCategories'],
    }),
    getClinicPatientClassificationInfo: builder.query<
      QueryResponseWithPagination<PatientClassification[]>,
      { clinicId: string; patientCategoryId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, patientCategoryId, params }) => ({
        url: `clinics/${clinicId}/patient-categories/${patientCategoryId}/clinic-patients`,
        params,
      }),
      providesTags: ['MedicalInstitutionPatientCategories'],
    }),
    getPatientInfo: builder.query<QueryResponseWithPagination<PatientShortInfo[]>, { params: QueryParams | undefined }>(
      {
        query: ({ params }) => ({
          url: `patients/small-info`,
          params,
        }),
        providesTags: ['PatientCategories'],
      },
    ),
    addPatientInfo: builder.mutation<void, { patientCategoryId: string; params: QueryParams | undefined }>({
      query: ({ params, patientCategoryId }) => ({
        url: `patient-categories/${patientCategoryId}/patients`,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['PatientCategories'],
    }),

    getClinicPatientInfo: builder.query<
      QueryResponseWithPagination<ClinicPatientShortInfo[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/clinic-patients/small-info`,
        params,
      }),
      providesTags: ['Patients', 'MedicalInstitutionPatients', 'MedicalInstitutionPatientCategories'],
    }),

    addClinicPatientInfo: builder.mutation<void, { patientCategoryId: string; params: QueryParams | undefined }>({
      query: ({ params, patientCategoryId }) => ({
        url: `clinics/patient-categories/${patientCategoryId}/patients`,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['MedicalInstitutionPatientCategories'],
    }),

    deletePatientInsuranceDetails: builder.mutation<void, { patientId: string; insuranceId: string }>({
      query: ({ patientId, insuranceId }) => ({
        url: `clinic-patients/${patientId}/insurances/${insuranceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PatientInsuranceDetails', 'MedicalInstitutionPatientInsuranceDetails'],
    }),

    editPatientInsuranceDetails: builder.mutation<
      void,
      { patientId: string; insuranceId: string; body: EditInsuranceDetailsBody }
    >({
      query: ({ patientId, insuranceId, body }) => ({
        url: `clinic-patients/${patientId}/insurances/${insuranceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PatientInsuranceDetails', 'MedicalInstitutionPatientInsuranceDetails'],
    }),

    addPatientInsuranceDetails: builder.mutation<void, { patientId: string; body: AddInsuranceDetailsBody }>({
      query: ({ patientId, body }) => ({
        url: `patients/${patientId}/insurances`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PatientInsuranceDetails'],
    }),

    getPatientInsuranceDetails: builder.query<
      QueryResponseWithPagination<InsuranceDetailsItem[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `patients/${patientId}/insurances`,
        params,
      }),
      providesTags: ['PatientInsuranceDetails'],
    }),

    getClinicInsuranceDetails: builder.query<
      QueryResponseWithPagination<InsuranceDetailsItem[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `clinic-patients/${patientId}/insurances`,
        params,
      }),
      providesTags: ['MedicalInstitutionPatientInsuranceDetails'],
    }),

    addClinicPatientInsuranceDetails: builder.mutation<void, { patientId: string; body: AddInsuranceDetailsBody }>({
      query: ({ patientId, body }) => ({
        url: `clinic-patients/${patientId}/insurances`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientInsuranceDetails'],
    }),

    getPatientDetails: builder.query<QueryResponse<Patient>, string>({
      query: (patientId) => ({
        url: `patients/${patientId}/page-details`,
      }),
      providesTags: ['PatientInfo'],
    }),

    getClinicPatientDetails: builder.query<
      QueryResponse<ClinicDetailedPatient>,
      { clinicId: string; patientId: string }
    >({
      query: ({ clinicId, patientId }) => ({
        url: `clinics/${clinicId}/clinic-patients/${patientId}`,
      }),
      providesTags: ['MedicalInstitutionPatientInfo'],
    }),

    getClinicRelatedProfile: builder.query<
      QueryResponseWithPagination<RelatedProfile[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `clinic-patients/${patientId}/possible-related-patients`,
        params,
      }),
      providesTags: ['MedicalInstitutionRelatedPatient'],
    }),

    getPatientClinicBalances: builder.query<QueryResponse<PatientAccountBalance>, string>({
      query: (patientId) => ({ url: `wallets/summary/clinic-patients/${patientId}` }),
      providesTags: ['PatientAccountBalance'],
    }),

    getDependentGuardians: builder.query<
      QueryResponseWithPagination<PatientGuardian[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `dependants/${patientId}/guardians`,
        params,
      }),
      providesTags: ['MedicalInstitutionPatientGuardians'],
    }),

    addDependentGuardians: builder.mutation<void, { patientId: string; body: AddDependentGuardiansBody[] }>({
      query: ({ patientId, body }) => ({
        url: `dependants/${patientId}/link`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientGuardians', 'MedicalInstitutionRelatedPatient'],
    }),

    editDependentGuardians: builder.mutation<
      void,
      { guardianId: string; dependantId: string; body: { relation: string; otherRelationText: string } }
    >({
      query: ({ guardianId, dependantId, body }) => ({
        url: `clinic-patients/${guardianId}/dependants/${dependantId}/link`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MedicalInstitutionPatientGuardians'],
    }),

    deleteDependentGuardians: builder.mutation<void, { guardianId: string; clinicPatientId: string }>({
      query: ({ guardianId, clinicPatientId }) => ({
        url: `clinic-patients/${guardianId}/dependants/${clinicPatientId}/link`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MedicalInstitutionPatientGuardians'],
    }),

    checkMergeIsPossible: builder.query<
      QueryResponse<CheckIfRevertMergeIsPossibleBody>,
      { fromClinicId: string; toClinicPatientId: string }
    >({
      query: ({ fromClinicId, toClinicPatientId }) => ({
        url: `clinic-patients/${fromClinicId}/check-merge-is-possible-with/${toClinicPatientId}`,
      }),
    }),

    getMergeClinicPatients: builder.query<
      QueryResponse<MergeProfilesData>,
      { fromClinicId: string; toClinicPatientId: string }
    >({
      query: ({ fromClinicId, toClinicPatientId }) => ({
        url: `clinic-patients/${fromClinicId}/merge-to/${toClinicPatientId}/confirm-window`,
      }),
    }),

    getMergeClinicPatientsById: builder.query<QueryResponse<MergeProfilesData>, { mergeRequestId: string }>({
      query: ({ mergeRequestId }) => ({
        url: `clinic-patients/merge-requests/${mergeRequestId}/confirm-window`,
      }),
      providesTags: ['MergeClinicPatient'],
    }),

    mergeClinicPatients: builder.mutation<QueryResponse<string>, MergeRequestPatientBodyParams>({
      query: (body) => ({
        url: `clinic-patients/merge-requests/process`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'MedicalInstitutionDependents',
        'MedicalInstitutionRelatedPatient',
        'MedicalInstitutionPatientInfo',
        'MergeClinicPatient',
      ],
    }),
    cancelMergeRequest: builder.mutation<void, { mergeRequestId: string }>({
      query: ({ mergeRequestId }) => ({
        url: `clinic-patients/merge-requests/${mergeRequestId}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: ['MedicalInstitutionDependents', 'MedicalInstitutionRelatedPatient'],
    }),
    checkVerificationCode: builder.mutation<
      QueryResponse<CheckIfRevertMergeIsPossibleBody>,
      { mergeRequestId: string; body: VerificationCodeMergeRequestPatientBodyParams }
    >({
      query: ({ mergeRequestId, body }) => ({
        url: `clinic-patients/merge-requests/${mergeRequestId}/confirm`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'MedicalInstitutionDependents',
        'MedicalInstitutionRelatedPatient',
        'MedicalInstitutionPatientInfo',
      ],
    }),
    sendVerificationCode: builder.mutation<void, { mergeRequestId: string }>({
      query: ({ mergeRequestId }) => ({
        url: `clinic-patients/merge-requests/${mergeRequestId}/send-new-confirmation-code`,
        method: 'PUT',
      }),
      invalidatesTags: ['MedicalInstitutionDependents', 'MedicalInstitutionRelatedPatient'],
    }),
    requireVerificationPatient: builder.mutation<void, string>({
      query: (patientId) => ({
        url: `patients/${patientId}/require-verification`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientInfo'],
    }),

    blockPatients: builder.mutation<void, string>({
      query: (id) => ({
        url: `patients/${id}/block`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientInfo', 'Patients'],
    }),

    unblockPatients: builder.mutation<void, string>({
      query: (id) => ({
        url: `patients/${id}/unblock`,
        method: 'PUT',
      }),
      invalidatesTags: ['PatientInfo', 'Patients'],
    }),

    blockClinicPatients: builder.mutation<void, { id: string; clinicId: string }>({
      query: ({ id, clinicId }) => ({
        url: `patients/${id}/clinics/blocked`,
        method: 'POST',
        body: [clinicId],
      }),
      invalidatesTags: ['MedicalInstitutionPatientInfo', 'MedicalInstitutionPatients'],
    }),

    unblockClinicPatients: builder.mutation<void, { id: string; clinicId: string }>({
      query: ({ id, clinicId }) => ({
        url: `patients/${id}/clinics/unblocked`,
        method: 'POST',
        body: [clinicId],
      }),
      invalidatesTags: ['MedicalInstitutionPatientInfo', 'MedicalInstitutionPatients'],
    }),

    checkClinicGovernmentIssuedId: builder.query<
      QueryResponse<CheckGovernmentIssuedIdResponse>,
      CheckClinicGovernmentIssuedIdParams
    >({
      query: ({ clinicId, governmentIssuedId, governmentIssuedIdType }) => ({
        url: `/clinics/${clinicId}/clinic-patients/is-government-issued-id-used`,
        method: 'GET',
        params: {
          governmentIssuedId,
          governmentIssuedIdType,
        },
      }),
    }),
    checkGlobalGovernmentIssuedId: builder.query<
      QueryResponse<CheckGovernmentIssuedIdResponse>,
      CheckGlobalGovernmentIssuedIdParams
    >({
      query: ({ patientId, governmentIssuedId, governmentIssuedIdType }) => ({
        url: `patients/is-government-issued-id-used`,
        method: 'GET',
        params: {
          patientId,
          governmentIssuedId,
          governmentIssuedIdType,
        },
      }),
    }),
  }),
});

export const {
  // Patients
  useLazySearchClinicPatientByPhoneNumberQuery,
  useLazySearchPatientByPhoneNumberQuery,
  useLazyGetEditDependentInfoQuery,
  useGetPatientsQuery,
  useGetClinicPatientsQuery,
  useGetPatientPageDetailsQuery,
  useLazyGetPatientPageDetailsQuery,
  useGetPatientShortDetailsQuery,
  useAddPatientMutation,
  useEditPatientMutation,
  useAddClinicPatientMutation,
  useEditClinicPatientMutation,
  useGetPatientDependentsQuery,
  useGetPatientRegistrationsQuery,
  useGetPatientGlobalRatingQuery,
  useGetMedicalInstitutionPatientRatingQuery,
  useGetPatientClassificationInfoQuery,
  useGetClinicPatientClassificationInfoQuery,
  useGetClinicPatientInfoQuery,
  useLazyGetClinicPatientInfoQuery,
  useGetPatientInfoQuery,
  useAddPatientInfoMutation,
  useAddClinicPatientInfoMutation,
  useGetPatientInsuranceDetailsQuery,
  useAddPatientInsuranceDetailsMutation,
  useEditPatientInsuranceDetailsMutation,
  useDeletePatientInsuranceDetailsMutation,
  useGetClinicInsuranceDetailsQuery,
  useAddClinicPatientInsuranceDetailsMutation,
  useGetPatientDetailsQuery,
  useGetClinicPatientDetailsQuery,
  useUpdatedClinicPatientMutation,
  useUpdatedPatientMutation,
  useUpdateStatisticsMutation,
  useGetClinicRelatedProfileQuery,
  useGetPatientClinicBalancesQuery,
  useAddDependentGuardiansMutation,
  useGetDependentGuardiansQuery,
  useDeleteDependentGuardiansMutation,
  useEditDependentGuardiansMutation,
  useGetClinicPatientDependentsQuery,
  useAddClinicPatientDependentMutation,
  useEditClinicPatientDependentMutation,
  useConvertClinicPatientDependentMutation,
  useDeleteClinicPatientDependentMutation,
  useSwitchClinicPatientDependentMutation,
  useRequireVerificationPatientMutation,
  useBlockPatientsMutation,
  useUnblockPatientsMutation,
  useBlockClinicPatientsMutation,
  useUnblockClinicPatientsMutation,
  useCancelPatientRequiredVerificationMutation,
  useGetRelatedPatientsForAppointmentQuery,
  useVerifyPatientProfileMutation,
  // Logs
  useGetLinkingHistoryQuery,
  useGetPatientLogQuery,
  useLazyGetClinicPatientLogExportQuery,
  useGetClinicPatientLogQuery,
  useGetPatientIdentityLogQuery,
  useRequireVerificationMutation,
  useCancelRequiredVerificationMutation,
  useVerifyMutation,
  useLazyGetPatientLogExportQuery,
  useUnmergePatientsMutation,
  useMergedByInfoQuery,
  useLazyGetMedicalInstitutionsPatientLogExportQuery,
  useGetMedicalInstitutionsPatientLogQuery,
  // Merges
  useGetMergeClinicPatientsByIdQuery,
  useLazyGetMergeClinicPatientsByIdQuery,
  useCancelMergeRequestMutation,
  useSendVerificationCodeMutation,
  useCheckVerificationCodeMutation,
  useLazyCheckMergeIsPossibleQuery,
  useGetMergeClinicPatientsQuery,
  useGetPatientMergeBadgeDetailsQuery,
  useGetPatientListMergeBadgeDetailsQuery,
  useMergeClinicPatientsMutation,
  useLazyCheckUnmergeIsPossibleQuery,
  useGetClinicPatientMergeQuery,
  useLazyGetClinicPatientMergeExportQuery,
  useLazyGetPatientMergeExportQuery,
  useGetPatientMergeQuery,
  useCheckIfRevertMergeIsPossibleQuery,
  useClassificationMassAddingMutation,
  useClinicPatientClassificationMassAddingMutation,
  useEditClinicPatientClassificationMutation,
  useEditPatientClassificationMutation,
  useGetClinicPatientClassificationQuery,
  useGetPatientClassificationQuery,
  useAddClinicPatientClassificationMutation,
  useAddPatientClassificationMutation,
  useMovePatientClassificationMutation,
  useMoveClinicPatientClassificationMutation,
  useDeletePatientClassificationMutation,
  useDeleteClinicPatientClassificationMutation,
} = patientsApi;
